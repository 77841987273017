






import { Component, Vue } from 'vue-property-decorator';
import EventListCard from './EventListCard.vue';

@Component({
  components: {
    EventListCard,
  },
})
export default class EventList extends Vue {}
