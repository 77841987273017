




















































































































































import {Prop, Vue} from 'vue-property-decorator';
import Component from 'vue-class-component';
import { OutputDeviceEventDTO, OutputDeviceDTO, OutputLocationDTO, OutputOrganizationDTO } from '@/api/api';
import {formatObject} from "@/utils";
import Loading from "@/components/Loading.vue";

@Component({
  components: {Loading}
})
export default class EventView extends Vue {
  @Prop(Number) readonly organizationId;
  @Prop(Number) readonly locationId;
  @Prop(Number) readonly deviceId;
  @Prop(Number) readonly eventId;

  public event: OutputDeviceEventDTO|null = null;
  public device: OutputDeviceDTO|null = null;
  public location: OutputLocationDTO|null = null;
  public organization: OutputOrganizationDTO|null = null;
  public dataString: string = '';

  public async mounted() {
    // Call API in parallel
    const qEvent = this.$store.dispatch('deviceEvents/get', { deviceEventId: this.eventId })
    const qDevice = this.$store.dispatch('devices/get', { deviceId: this.deviceId });
    const qLocation = this.$store.dispatch('locations/get', { locationId: this.locationId });
    const qOrganisation = this.$store.dispatch('organizations/get', { organizationId: this.organizationId });

    this.event = await qEvent;
    this.device = await qDevice;
    this.location = await qLocation;
    this.organization = await qOrganisation;

    if (this.event && this.event.data) {
      this.dataString = formatObject(this.event.data);
    } else {
      this.dataString = '';
    }
  }
}
