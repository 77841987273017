var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"ma-3 pa-3"},[(!_vm.event || !_vm.location)?_c('loading'):[_c('v-subheader',[_c('div',[_vm._v("Event")]),_c('v-spacer'),_c('div',{staticClass:"px-3"},[(_vm.event.severity === 'info')?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" info ")]):(_vm.event.severity === 'warning')?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" warning ")]):(_vm.event.severity === 'error')?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" error ")]):_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" radio-button-unchecked ")])],1)],1),_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v(" Event "+_vm._s(_vm.event.id)+" at "+_vm._s(_vm.location.name)+" ")]),_c('v-spacer'),_c('v-subheader',[_c('div',[_vm._v(_vm._s(_vm.event.id))])])],1),_c('v-form',{staticClass:"pa-3"},[_c('v-text-field',{attrs:{"label":"Timestamp","placeholder":"none","readonly":""},model:{value:(_vm.event.timestamp),callback:function ($$v) {_vm.$set(_vm.event, "timestamp", $$v)},expression:"event.timestamp"}}),_c('v-text-field',{attrs:{"label":"Message","placeholder":"none","readonly":""},model:{value:(_vm.event.message),callback:function ($$v) {_vm.$set(_vm.event, "message", $$v)},expression:"event.message"}}),_c('v-textarea',{attrs:{"label":"Data","placeholder":"none","readonly":""},model:{value:(_vm.dataString),callback:function ($$v) {_vm.dataString=$$v},expression:"dataString"}})],1)]],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-subheader',[_vm._v("Organisation")]),(!_vm.organization)?_c('loading'):[_c('v-card-title',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.organization.name))])]),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":{
                  name: 'main-maintenance-organisation-view',
                  params: {
                    organizationId: _vm.organizationId,
                  },
                }}},[_c('v-icon',[_vm._v("pageview")])],1)],1)]],2),_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-subheader',[_vm._v("Location")]),(!_vm.location)?_c('loading'):[_c('v-card-title',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.location.name))])]),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":{
                  name: 'main-maintenance-location-view',
                  params: {
                    organizationId: _vm.organizationId,
                    locationId: _vm.locationId
                  },
                }}},[_c('v-icon',[_vm._v("pageview")])],1)],1)]],2),_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-subheader',[_vm._v("Device")]),(!_vm.device)?_c('loading'):[_c('v-card-title',[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.device.device_type.name)+" "+_vm._s(_vm.device.external_id)+" ")])]),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":{
                  name: 'main-maintenance-device-view',
                  params: {
                    organizationId: _vm.organizationId,
                    locationId: _vm.locationId,
                    deviceId: _vm.deviceId
                  },
                }}},[_c('v-icon',[_vm._v("pageview")])],1)],1)]],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }